// src/components/packagesmodules/PrivacyPolicyPopup.js

import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import './PrivacyPolicyPopup.css';
import { useLocation } from 'react-router-dom';
import TermsOfServicePopup from './TermsOfServicePopup';

function PrivacyPolicyPopup({ formData, onClose, selectedPackage }) {
  const location = useLocation();
  const fromFooter = location.state?.fromFooter || false;

  const [isChecked, setIsChecked] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isTosOpen, setIsTosOpen] = useState(false);
  
  const popupRef = useRef(null);
  const tosRef = useRef(null); // Ref for the ToS popup

  // Handle checkbox state
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  // Handle form submission when the button is clicked
  const handleButtonClick = async () => {
    if (isChecked && !fromFooter) { // Only proceed if the checkbox is checked and NOT from footer
      setIsSubmitting(true); // Disable button during submission
      try {
        console.log('Sending form data:', formData); // Log the formData before sending
        const response = await fetch('/api/users', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ...formData, // Spread in the form data from the GetStarted form
            packageSelected: selectedPackage, // Include the selected package
          }),
        });

        if (response.ok) {
          const data = await response.json();
          console.log('User data submitted successfully:', data); // Log success
          onClose(); // Close the popup after successful submission
        } else {
          console.error('Error submitting user data:', response.statusText); // Log error if submission fails
        }
      } catch (error) {
        console.error('Error submitting user data:', error); // Log the error
      } finally {
        setIsSubmitting(false); // Re-enable the button after the process
      }
    } else {
      // If from footer or checkbox unchecked, just close the popup
      onClose();
    }
  };

  // Close the Privacy Policy popup if user clicks outside of it or the ToS popup
  useEffect(() => {
    const handleClickOutside = (event) => {
      const isClickInsidePrivacyPolicy = popupRef.current && popupRef.current.contains(event.target);
      const isClickInsideToS = tosRef.current && tosRef.current.contains(event.target);
      if (!isClickInsidePrivacyPolicy && !isClickInsideToS) {
        onClose(); // Close the Privacy Policy popup if clicked outside both popups
      }
    };

    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        onClose(); // Close the Privacy Policy popup on Esc key press
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEsc);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEsc);
    };
  }, [onClose]);

  // Function to open the ToS popup
  const openTosPopup = () => {
    setIsTosOpen(true);
  };

  // Function to close the ToS popup
  const closeTosPopup = () => {
    setIsTosOpen(false);
  };

  useEffect(() => {
    if (isTosOpen) {
      // Prevent background scrolling when ToS popup is open
      document.body.style.overflow = 'hidden';
    } else {
      // Re-enable background scrolling when ToS popup is closed
      document.body.style.overflow = 'auto';
    }
  }, [isTosOpen]);

  return ReactDOM.createPortal(
    <>
      <div className="privacy-policy-popup" role="dialog" aria-modal="true">
        <div className="popup-contentprivacy" ref={popupRef}>
          <h2>Fix My Score - Privacy Policy</h2>
          <p>Last updated: April 23, 2024</p>
          
          <div className="policy-content">
<h3>Introduction</h3>
<p>
  At Fix My Score (the “Company” or “We”), we respect your privacy and are committed to protecting it through our compliance with this policy. This Privacy Policy explains the information we may collect from you, how we use it, and your rights in relation to that information.
</p>

<h4>This policy applies to data collected:</h4>
<ul>
  <li>On this Website.</li>
  <li>In electronic messages between you and this Website.</li>
  <li>Through mobile or desktop applications you download from this Website.</li>
  <li>When you interact with our advertising or applications on third-party websites linked to this policy.</li>
</ul>

<h4>This policy does not apply to:</h4>
<ul>
  <li>Information collected offline or through other means by the Company or any third party.</li>
  <li>Information collected by third parties through applications or content (including advertising) that may link to or be accessible from the Website.</li>
</ul>

<p>
  Please read this policy carefully. By using this Website, you consent to our data practices as outlined here. This policy may be updated periodically; continued use of our Website after changes constitutes acceptance of the revised policy.
</p>

<h4>Children Under the Age of 13</h4>
<p>
  Our Website is not intended for children under 13. We do not knowingly collect personal information from children under 13. If we become aware that information from a child under 13 was collected without parental consent, we will delete it. Please contact us if you believe we may have collected such information.
</p>

<h4>Information We Collect About You and How We Collect It</h4>
<p>
  We may collect various types of information, including:
</p>
<ul>
  <li>
    <strong>1. Personal Information:</strong> Such as your name, postal address, email address, telephone number, or any information defined as personal or personally identifiable by law.
  </li>
  <li>
    <strong>2. Non-Personal Information:</strong> Data that does not personally identify you.
  </li>
  <li>
    <strong>3. Device and Usage Information:</strong> Such as IP addresses, browser type, and usage details, collected automatically through tracking technologies.
  </li>
</ul>

<h4>We collect information:</h4>
<ul>
  <li>Directly from you when provided on our Website.</li>
  <li>Automatically as you navigate the Website, using cookies, web beacons, and other tracking tools.</li>
  <li>From third parties, such as business partners.</li>
</ul>

<h4>Information You Provide to Us</h4>
<p>
  Information we collect may include:
</p>
<ul>
  <li>Data from forms filled on our Website, including information provided during registration, subscription, or inquiries.</li>
  <li>Records of correspondence if you contact us.</li>
  <li>Survey responses, transaction details, and search queries.</li>
</ul>

<h4>Automatic Data Collection Technologies</h4>
<p>
  As you interact with our Website, we may use technologies to collect information, including:
</p>
<ul>
  <li><strong>Cookies:</strong> Small files on your hard drive that store data on your preferences and interaction with our Website.</li>
  <li><strong>Flash Cookies:</strong> Local storage for settings and navigation history.</li>
  <li><strong>Web Beacons:</strong> Electronic files that track page visits and email opens.</li>
</ul>

<h4>Third-Party Use of Cookies and Tracking</h4>
<p>
  Certain third parties (e.g., advertisers) may use cookies and similar technologies to collect data on your browsing habits. We do not control these technologies or how they may be used. You may opt-out of targeted ads through your browser settings or directly with the advertiser.
</p>

<h4>How We Use Your Information</h4>
<p>
  We use collected information to:
</p>
<ul>
  <li>Operate and improve our Website.</li>
  <li>Provide services or information you request.</li>
  <li>Fulfill contractual obligations, such as billing and customer support.</li>
  <li>Offer notices about account updates and promotional content.</li>
  <li>Personalize Website experience by storing preferences and recognizing repeat visits.</li>
  <li>Ensure security and prevent fraud.</li>
</ul>

<p>
  With your consent, we may use your data for other purposes that we will disclose at the time of collection.
</p>

<h4>Disclosure of Your Information</h4>
<p>
  Fix My Score is committed to protecting customer information. We do not sell, share, or rent personal information to third parties except:
</p>
<ul>
  <li>As required by law, including in response to legal processes.</li>
  <li>To enforce our rights or protect property and safety.</li>
  <li>To third-party service providers assisting us in business operations (e.g., payment processors), bound by confidentiality obligations.</li>
</ul>

<h4>Accessing and Correcting Your Information</h4>
<p>
  You may contact us to request access, correction, or deletion of personal information. We will honor such requests unless it interferes with our legal obligations. Please note that deleted User Contributions may remain in cached or archived pages or could be copied by other users.
</p>

<h4>Data Retention</h4>
<p>
  We retain personal information only as long as necessary to fulfill the purposes for which it was collected or as required by law. Upon request and where feasible, we will delete or anonymize your data after service termination.
</p>

<h4>Your Privacy Rights</h4>
<p>
  While Kentucky does not have state-specific consumer privacy legislation like California's CCPA, Fix My Score adheres to federal privacy standards. You have the right to:
</p>
<ul>
  <li>Access, correct, or delete your data.</li>
  <li>Opt-out of third-party tracking where possible.</li>
  <li>Request clarification on how your data is used.</li>
</ul>

<p>
  For assistance, please contact us at <a href="mailto:support@fixmyscore.llc">support@fixmyscore.llc</a> or +1 (173) 954-783.
</p>

<h4>Data Security</h4>
<p>
  We implement security measures to protect your information from unauthorized access. However, online transmission of data is not entirely secure; we cannot guarantee absolute security. Please protect your account information and contact us immediately if you suspect any unauthorized access.
</p>

<h4>Changes to Our Privacy Policy</h4>
<p>
  We post any changes to this policy on this page. For material changes, we will notify you via email and/or a Website notice. The date of the last revision is listed at the top of this policy. It is your responsibility to keep your contact information up-to-date and check this page periodically for updates.
</p>

<h4>Contact Us</h4>
<p>
  For any questions about this Privacy Policy, please contact us:
</p>
<ul>
  <li>Email: <a href="mailto:support@fixmyscore.llc">support@fixmyscore.llc</a></li>
  <li>Phone: +1 (173) 954-783</li>
</ul>

<p>Thank you for trusting Fix My Score.</p>
</div>

          {/* Checkbox to agree to Privacy Policy and Terms of Service */}
          <div className="checkbox-container">
            <input
              type="checkbox"
              id="agree-checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="agree-checkbox">
              I agree to the{' '}
              <a href="#tos" onClick={(e) => { e.preventDefault(); openTosPopup(); }}>
                Terms of Service
              </a>{' '}
              and the Privacy Policy
            </label>
          </div>

          {/* Submit button that is disabled until checkbox is checked */}
          <button
            className="agree-button"
            disabled={!isChecked || isSubmitting} // Disable if not checked or submitting
            onClick={handleButtonClick}
          >
            {fromFooter ? "Close" : "Financial Freedom, Here I Come"}
          </button>
        </div>
      </div>

      {/* Render TermsOfServicePopup if isTosOpen is true */}
      {isTosOpen && <TermsOfServicePopup onClose={closeTosPopup} ref={tosRef} />}
    </>,
    document.getElementById('portal') // Ensure this div exists in your index.html
  );
}

export default PrivacyPolicyPopup;






