// ServiceCheckbox.js
import React from 'react';
import './ServiceCheckbox.css'; // Import the CSS for the service checkbox

function ServiceCheckbox({ name, label, onChange }) { // Removed 'cost' from props
  return (
    <div className="form-group">
      <label>
        <input type="checkbox" name={name} onChange={onChange} />{' '}
        {label} 
      </label>
    </div>
  );
}

export default ServiceCheckbox;
