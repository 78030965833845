import React from 'react';
import './PackageCard.css'; // Import the CSS for the package card

function PackageCard({ title, initialFee, monthlyFee, features, onClick }) {
  return (
    <div className="package-card" onClick={onClick} style={{ cursor: 'pointer' }}>
      <div className="package-title">{title}</div>
      <div className="package-price">
        <span>Initial Month Fee:</span> <strong>{initialFee}</strong><br />
        <span>Monthly fee:</span> <strong>{monthlyFee}</strong>
      </div>
      <ul className="package-features">
        {features.map((feature, index) => (
          <li key={index}>{feature}</li>
        ))}
      </ul>
    </div>
  );
}

export default PackageCard;
