// src/components/packagesmodules/TermsOfServicePopup.js

import React, { useEffect, useRef, forwardRef } from 'react';
import ReactDOM from 'react-dom';
import './TermsOfServicePopup.css'; // Ensure you create and style this CSS file

const TermsOfServicePopup = forwardRef(({ onClose }, ref) => {
  const internalRef = useRef(null);
  const popupRef = ref || internalRef;

  useEffect(() => {
    // Prevent background scrolling when ToS popup is open
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose(); // Close the ToS popup if clicked outside
      }
    };

    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        onClose(); // Close the ToS popup on Esc key press
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEsc);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEsc);
    };
  }, [onClose, popupRef]);

  return ReactDOM.createPortal(
    <div className="tos-popup" role="dialog" aria-modal="true" aria-labelledby="tos-title" aria-describedby="tos-description">
      <div className="popup-contenttos" ref={popupRef}>
        <div className="popup-header">
          <h2 id="tos-title">Terms of Service</h2>
          <button className="close-button" onClick={onClose} aria-label="Close Terms of Service">&times;</button>
        </div>
        <p id="tos-description">Effective Date: October 20, 2024</p>
        
        <div className="policy-content">
{/* Insert the ToS content here */}
<h3>1. Services Provided</h3>
<p>
  Fix My Score offers credit repair services, including negotiating with credit bureaus and creditors by submitting dispute letters. Our goal is to help clients improve their credit scores with actionable financial strategies. We cannot guarantee specific outcomes, as credit decisions are controlled by third parties, including credit bureaus and creditors, in compliance with CROA regulations.
</p>

<h3>2. Client Responsibilities</h3>
<p>
  Clients must provide accurate and complete information necessary for service delivery. This includes:
</p>
<ul>
  <li>Full legal name</li>
  <li>Residential address</li>
  <li>Email address</li>
  <li>Telephone number</li>
  <li>Relevant documents for disputes</li>
</ul>
<p>
  Failure to provide accurate information or cooperate with our team may result in termination of services without refund.
</p>

<h3>3. Pricing and Payment</h3>
<p>
  In compliance with CROA, no upfront fees will be charged before services are rendered. Payments will be collected only after initiating services, such as submitting the first dispute letter.
</p>
<p><strong>Credit Enhancement Plan:</strong></p>
<ul>
  <li>Initial fee (post-service): $59.99</li>
  <li>Monthly fee: $44.99</li>
</ul>
<p><strong>Credit Reset Plan:</strong></p>
<ul>
  <li>Initial fee (post-service): $84.99</li>
  <li>Monthly fee: $69.99</li>
</ul>
<p><strong>Custom Plan:</strong></p>
<p>
  A tailored plan for specific credit repair needs, with fees determined based on the services agreed upon. Contact us for more details.
</p>
<p>
  Timely payment is required. Non-payment may result in suspension or termination of services.
</p>

<h3>4. Refund Policy</h3>
<p>
  You are eligible for a refund if your credit score improves by less than 30 points within 90 days. Refund requests must be submitted in writing within 90 days from the start of services. Refunds are only applicable for services rendered and cannot be requested for fully completed services or beyond the refund window.
</p>

<h3>5. Service Timelines</h3>
<p>
  Credit bureaus typically respond to dispute letters within 30 days, but full results may take 1 to 3 months. Multiple dispute rounds may be necessary, depending on the nature of your credit report issues.
</p>

<h3>6. Dispute Process</h3>
<p>
  <strong>Credit Enhancement Plan:</strong> One round of disputes
</p>
<p>
  <strong>Credit Reset Plan:</strong> Up to five rounds of disputes
</p>
<p>
  <strong>Custom Plan:</strong> Tailored rounds of disputes based on client needs and plan details
</p>
<p>
  We provide dispute services during the selected plan’s period. However, we cannot guarantee the removal of disputed items or a guaranteed credit score increase, per federal regulations.
</p>

<h3>7. Limitations of Liability</h3>
<p>
  While we make every effort to improve your credit score, we cannot be held liable for third-party decisions made by credit bureaus or creditors. If a client fails to follow our advice or take necessary actions, services may be terminated without refund.
</p>

<h3>8. Termination of Services</h3>
<p>
  We reserve the right to terminate services for:
</p>
<ul>
  <li>Incomplete or inaccurate client information</li>
  <li>Non-compliance with payment schedules</li>
  <li>Uncooperative behavior, such as failing to act on creditor arrangements</li>
</ul>
<p>
  Termination will be executed in compliance with CROA guidelines, with proper notice to the client.
</p>

<h3>9. Right to Cancel</h3>
<p>
  Clients may cancel their contract within three business days of signing, as required by CROA. To cancel, submit a written request within the cancellation window to receive a full refund of any payments made.
</p>

<h3>10. Privacy Policy</h3>
<p>
  We are committed to protecting your personal data. All information provided will be handled in accordance with our Privacy Policy, ensuring compliance with relevant privacy laws and no unauthorized sharing of your data.
</p>

<h3>11. Dispute Resolution</h3>
<p>
  If you are dissatisfied with our services, please contact us directly for resolution. We aim to resolve disputes amicably. If resolution is not possible, remedies such as refunds may be offered. Clients retain the right to file complaints with the Federal Trade Commission (FTC) if they believe their rights under CROA have been violated.
</p>

<h3>12. Modification of Terms</h3>
<p>
  We may amend these Terms at any time. Clients will be notified of changes, and continued use of services after changes indicates acceptance. We encourage you to review these Terms periodically to stay informed of updates.
</p>

<h3>Contact Us</h3>
<p>
  For questions about these Terms or our services, please contact us at:
</p>
<ul>
  <li>Phone: +1 (173) 954-783</li>
  <li>Email: <a href="mailto:support@fixmyscore.llc">support@fixmyscore.llc</a></li>
</ul>
</div>

        {/* Optional: Add a footer or additional buttons if needed */}
      </div>
    </div>,
    document.getElementById('portal') // Ensure this div exists in your index.html
  );
});

export default TermsOfServicePopup;





