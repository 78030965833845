import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './GetStarted.css';
import PrivacyPolicyPopup from './packagesmodules/PrivacyPolicyPopup'; // Import the Privacy Policy Popup

function GetStarted() {
  const location = useLocation();
  
  // Determine if we're redirected from the packages page
  const redirectedFromPackages = !!location.state?.selectedPackage;

  // Form state including the selected package passed from Packages
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    city: '',
    zip: '',
    referralCode: '',
    selectedPackage: location.state?.selectedPackage || '', // Get selected package from location state if available
    services: location.state?.services || [],  // Retrieve selected services if applicable
    total: location.state?.total || '',
    initialFee: location.state?.initialFee || '',
  });

  const [showPopup, setShowPopup] = useState(false); // State to control popup visibility
  const [errors, setErrors] = useState({}); // State to store validation errors
  const navigate = useNavigate();

  // Helper function to validate phone numbers (allow only numbers, (), spaces, and -)
  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^[0-9()\-\s]+$/;
    return phoneRegex.test(phone);
  };

  // Helper function to validate zip codes (allow only numbers)
  const validateZipCode = (zip) => {
    const zipRegex = /^[0-9]+$/;
    return zipRegex.test(zip);
  };

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Validate phone number and zip code on input
    if (name === 'phone' && !validatePhoneNumber(value)) {
      setErrors((prevErrors) => ({ ...prevErrors, phone: 'Phone number can only contain digits, (), -, and spaces.' }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, phone: '' }));
    }

    if (name === 'zip' && !validateZipCode(value)) {
      setErrors((prevErrors) => ({ ...prevErrors, zip: 'Zip code can only contain digits.' }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, zip: '' }));
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Check for any errors before submitting the form
    if (errors.phone || errors.zip) {
      console.log('Form contains errors:', errors);
      return; // Don't submit if there are validation errors
    }

    console.log('Form Data:', formData); 

    // If user was redirected from packages page, show the popup
    if (redirectedFromPackages) {
      setShowPopup(true); // Show privacy policy popup after form submission
    } else {
      // Original flow: redirect to packages page with form data
      navigate('/packages', { state: { formData } });
    }
  };

  // Handle closing of the popup
  const handleClosePopup = () => {
    setShowPopup(false);
    // Perform any further actions like sending the data to the server here
    console.log("Submitting form data after closing popup: ", formData);
    // Send the form data to the server or proceed further
  };

  return (
    <div className="get-started-container">
      <div className="progress-bar">
        <div className="step active">1. Contact Information</div>
        <div className="step">2. Select Your Service Level</div>
        <div className="step">3. Payment Information</div>
        <div className="step">4. Agreement</div>
        <div className="step">5. Finish</div>
      </div>
      <h2>Congratulations on taking a smart step!</h2>
      <p className="subheading">To sign up please enter the following information.</p>
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="form-group">
            <label>First Name</label>
            <input
              type="text"
              name="firstname"
              value={formData.firstname}
              onChange={handleChange}
              required
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Last Name</label>
            <input
              type="text"
              name="lastname"
              value={formData.lastname}
              onChange={handleChange}
              required
              className="form-control"
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>City</label>
            <input
              type="text"
              name="city"
              value={formData.city}
              onChange={handleChange}
              required
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Zip Code</label>
            <input
              type="text"
              name="zip"
              value={formData.zip}
              onChange={handleChange}
              required
              className="form-control"
            />
            {errors.zip && <span className="error-message">{errors.zip}</span>}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Phone Number</label>
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
              className="form-control"
            />
            {errors.phone && <span className="error-message">{errors.phone}</span>}
          </div>
          <div className="form-group">
            <label>Email Address</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="form-control"
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Referral Code</label>
            <input
              type="text"
              name="referralCode"
              value={formData.referralCode}
              onChange={handleChange}
              className="form-control"
            />
          </div>
        </div>
        {/* Pass the selected package along with the rest of the form data */}
        <input
          type="hidden"
          name="selectedPackage"
          value={formData.selectedPackage}
        />
        <input
          type="hidden"
          name="services"
          value={formData.services.join(', ')} // Join services into a single string
        />
        <input
          type="hidden"
          name="total"
          value={formData.total}
        />
        <input
          type="hidden"
          name="initialFee"
          value={formData.initialFee}
        />
        <button type="submit" className="btn btn-primary">Submit</button>
      </form>

      {/* Show Privacy Policy Popup after form submission if redirected from packages */}
      {redirectedFromPackages && showPopup && (
        <PrivacyPolicyPopup
          formData={formData} // Pass the formData to the popup
          selectedPackage={formData.selectedPackage} // Pass the selected package
          onClose={handleClosePopup} // Handle closing the popup
        />
      )}
    </div>
  );
}

export default GetStarted;
