import React from 'react';
import './ReferFriend.css'; // Import the CSS for styling

const ReferFriend = () => {
  return (
    <div className="refer-container">
      <h1 className="refer-heading">Refer a Friend and Save!</h1>
      <p className="refer-subheading">Join our referral program and enjoy exclusive savings for you and your friends.</p>
      
      <div className="refer-content">
        <p>
          As a thank you for recommending us, you’ll receive a 
          <span className="highlight"> 15% discount </span> 
          on your next service. Your friend will also enjoy a 
          <span className="highlight"> 10% discount </span> 
          on their first service with us!
        </p>

        <div className="refer-action">
          <h2>How It Works:</h2>
          <ul>
            <li>Share our services with your friends and family.</li>
            <li>They will receive a 10% discount on their first service.</li>
            <li>Once they sign up, you’ll get 15% off your next service!</li>
          </ul>
        </div>

        <div className="refer-info">
          <h2>Ready to Refer?</h2>
          <p>
            Send your friend’s details to <a href="mailto:support@fixmyscore.llc">support@fixmyscore.llc</a> and start saving today!
          </p>
        </div>
      </div>
    </div>
  );
};

export default ReferFriend;
