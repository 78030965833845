// App.js
import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import MainContent from './components/MainContent';
import Packages from './components/Packages';
import GetStarted from './components/GetStarted';
import Footer from './components/Footer';
import FaqPage from './components/FaqPage'; // Import FAQ page
import ReferFriend from './components/ReferFriend'; // Import Refer a Friend page
import './App.css';

function App() {
  const [animationComplete, setAnimationComplete] = useState(false);

  // Function to fetch CSRF token and store it in localStorage
  const fetchCsrfToken = useCallback(async () => {
    try {
      const response = await fetch('/api/csrf-token', {
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch CSRF token: ${response.statusText}`);
      }

      const data = await response.json();

      if (process.env.NODE_ENV === 'development') {
        // Uncomment the line below for debugging in development
        // console.log('CSRF token fetched:', data.csrfToken);
      }

      if (data.csrfToken) {
        localStorage.setItem('csrfToken', data.csrfToken);
      } else {
        throw new Error('CSRF token not found in the response');
      }
    } catch (error) {
      console.error('Error fetching CSRF token:', error);
    }
  }, []);

  // Fetch CSRF token on component mount
  useEffect(() => {
    fetchCsrfToken();
  }, [fetchCsrfToken]);

  // Add Tawk.to script for live support
  useEffect(() => {
    // Create script element
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://embed.tawk.to/6722858c4304e3196adad04c/1ibfdjbe3'; // Replace with your specific Tawk.to script URL
    script.charset = 'UTF-8';
    script.setAttribute('crossorigin', '*');

    // Append script to the body
    document.body.appendChild(script);

    // Initialize Tawk_API if needed
    window.Tawk_API = window.Tawk_API || {};
    window.Tawk_LoadStart = new Date();

    // Cleanup function to remove the script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array ensures this runs once on mount

  // Handle animation
  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimationComplete(true);
    }, 5000); // 5 seconds

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <div className="App">
        <Navbar />
        <div className="navbar-gradient"></div>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <MainContent />
                {/* Credit card animation removed */}
                {/* {animationComplete && (
                  <img
                    src={creditCardBackground}
                    alt="Credit Card Background"
                    className="credit-card-background"
                    style={{ display: 'block', margin: 'auto', width: '50%' }}
                  />
                )} */}
              </>
            }
          />
          <Route path="/packages" element={<Packages />} />
          <Route path="/get-started" element={<GetStarted />} />
          <Route path="/faq" element={<FaqPage />} /> {/* Add FAQ route */}
          <Route path="/refer" element={<ReferFriend />} /> {/* Add Refer a Friend route */}
        </Routes>

        {/* Add Footer here to show it on every page */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
