import React, { useState } from 'react';
import './FaqPage.css'; // Import the CSS file

const FaqPage = () => {
  const [activeIndex, setActiveIndex] = useState(null); // State to handle the active question

  const toggleQuestion = (index) => {
    setActiveIndex(index === activeIndex ? null : index); // Toggle active question
  };

  const faqs = [
    {
      question: 'What is credit repair?',
      answer:
        'Credit repair is the process of addressing errors, inaccuracies, or negative items on your credit report that could be lowering your credit score.',
    },
    {
      question: 'How long does credit repair take?',
      answer:
        'Every case is different, but typically, you can expect to see changes in your credit report within 30 to 90 days.',
    },
    {
      question: 'Can all negative items be removed from my credit report?',
      answer:
        'Not all negative items can be removed, but if they are inaccurate, outdated, or unverifiable, we can challenge them for deletion.',
    },
    {
      question: 'Will my credit score improve?',
      answer:
        'While we cannot guarantee a specific outcome, many clients see significant improvements as a result of our services.',
    },
    {
      question: 'How much does credit repair cost?',
      answer:
        'We offer various plans to fit your needs. Visit our Packages page for details on pricing.',
    },
    {
      question: 'Is Fix My Score legitimate?',
      answer:
        'Yes! We are a licensed and bonded credit repair company that follows all legal guidelines and regulations.',
    },
    {
      question: 'Will credit repair hurt my credit?',
      answer:
        'No, credit repair itself will not hurt your credit. However, the credit bureaus will review the accuracy of your disputes.',
    },
    {
      question: 'How do I get started?',
      answer:
        'Simply sign up on our website, and we’ll provide a free consultation to evaluate your credit situation.',
    },
    {
      question: 'What types of accounts can you help with?',
      answer:
        'We help with all types of accounts, including credit cards, loans, medical bills, and collections.',
    },
    {
      question: 'Is my personal information secure?',
      answer:
        'Yes, we follow strict security protocols to ensure that all your information is encrypted and securely stored.',
    },
  ];

  return (
    <div className="faq-container">
      <h1 className="faq-heading">Frequently Asked Questions</h1>
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className={`faq-item ${activeIndex === index ? 'active' : ''}`}
          >
            <div
              className="faq-question"
              onClick={() => toggleQuestion(index)}
            >
              {faq.question}
              <span className={`faq-icon ${activeIndex === index ? 'open' : ''}`}>
                {activeIndex === index ? '-' : '+'}
              </span>
            </div>
            {activeIndex === index && (
              <div className="faq-answer">{faq.answer}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FaqPage;
