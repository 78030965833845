// src/components/packagesmodules/RefundPolicyPopup.js

import React, { useEffect, useRef, forwardRef } from 'react';
import ReactDOM from 'react-dom';
import './RefundPolicyPopup.css'; // Ensure you create and style this CSS file

const RefundPolicyPopup = forwardRef(({ onClose }, ref) => {
  const internalRef = useRef(null);
  const popupRef = ref || internalRef;

  useEffect(() => {
    // Prevent background scrolling when Refund Policy popup is open
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose(); // Close the Refund Policy popup if clicked outside
      }
    };

    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        onClose(); // Close the Refund Policy popup on Esc key press
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEsc);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEsc);
    };
  }, [onClose, popupRef]);

  return ReactDOM.createPortal(
    <div
      className="refund-popup"
      role="dialog"
      aria-modal="true"
      aria-labelledby="refund-title"
      aria-describedby="refund-description"
    >
      <div className="popup-contentrefund" ref={popupRef}>
        <div className="popup-header">
          <h2 id="refund-title">Refund Policy</h2>
          <button
            className="close-button"
            onClick={onClose}
            aria-label="Close Refund Policy"
          >
            &times;
          </button>
        </div>
        <p id="refund-description">Effective Date: 31/10/2024</p>

        <div className="policy-content">
          {/* Refund Policy Content */}
          <p>
            <strong>Fix My Score - Refund Policy</strong>
          </p>
          <p>
            At Fix My Score, we are committed to helping you improve your credit score. However, credit repair depends on credit bureaus’ responses to disputes, and we cannot guarantee specific outcomes or timelines due to these factors.
          </p>

          <h3>Refund Eligibility</h3>
          <p>
            We offer a 90-day refund policy to ensure satisfaction. If, after 90 days from the date of signup, no meaningful improvements appear on your credit report, you may qualify for a refund.
          </p>

          <h3>Conditions for Refund:</h3>
          <ol>
            <li>
              <strong>Timeframe:</strong> You must be an active client for at least 90 days before requesting a refund.
            </li>
            <li>
              <strong>Service Completion:</strong> Our team must have completed multiple rounds of dispute letters (typically up to five) with documented submissions to the credit bureaus.
            </li>
            <li>
              <strong>Results:</strong> If your credit score has improved by less than 20 points after 90 days and you are dissatisfied, you may be eligible for a refund.
            </li>
            <li>
              <strong>Client Obligations:</strong> You must provide accurate information and respond to requests for information, including updates from credit bureaus. Failure to cooperate or provide timely responses may void refund eligibility.
            </li>
            <li>
              <strong>Exclusions:</strong> Refunds are not issued if delays are due to credit bureau or creditor response times, nor if the client’s account was inactive during the 90-day period.
            </li>
            <li>
              <strong>No Guarantees:</strong> Since specific results are not guaranteed under federal law, refunds are based solely on failure to meet outlined service processes and milestones, not on final credit score outcomes if the services were fully delivered.
            </li>
          </ol>

          <h3>How to Request a Refund</h3>
          <p>
            <strong>Request Timing:</strong> To request a refund, submit a formal request within 10 days after the 90-day period has concluded.
          </p>
          <p>
            <strong>Process:</strong> Email your refund request to <a href="mailto:support@fixmyscore.llc">support@fixmyscore.llc</a>. Our team will review and notify you of approval or denial within 10 business days.
          </p>
        </div>
      </div>
    </div>,
    document.getElementById('portal') // Ensure this div exists in your index.html
  );
});

export default RefundPolicyPopup;
