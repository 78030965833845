// src/components/Footer.js

import React, { useState } from 'react';
import './Footer.css'; // Add custom styling here
import logo from './pictures/logo.png';
import PrivacyPolicyPopup from './packagesmodules/PrivacyPolicyPopup'; // Import the popup
import TermsOfServicePopup from './packagesmodules/TermsOfServicePopup'; // Import ToS popup
import RefundPolicyPopup from './packagesmodules/RefundPolicyPopup'; // Import Refund Policy popup
import { Link } from "react-router-dom";

export default function Footer() {
  const [showPrivacyPopup, setShowPrivacyPopup] = useState(false); // Privacy Policy popup
  const [showTermsPopup, setShowTermsPopup] = useState(false); // Terms of Service popup
  const [showRefundPopup, setShowRefundPopup] = useState(false); // Refund Policy popup

  // Handlers for Privacy Policy
  const handlePrivacyPolicyClick = (e) => {
    e.preventDefault(); // Prevent default anchor behavior
    setShowPrivacyPopup(true); // Show the popup
  };

  const handleClosePrivacyPopup = () => {
    setShowPrivacyPopup(false); // Close the popup
  };

  // Handlers for Terms of Service
  const handleTermsOfServiceClick = (e) => {
    e.preventDefault();
    setShowTermsPopup(true);
  };

  const handleCloseTermsPopup = () => {
    setShowTermsPopup(false);
  };

  // Handlers for Refund Policy
  const handleRefundPolicyClick = (e) => {
    e.preventDefault();
    setShowRefundPopup(true);
  };

  const handleCloseRefundPopup = () => {
    setShowRefundPopup(false);
  };

  return (
    <footer className="footer bg-light text-center text-lg-start text-muted">
      {/* Social Media Links */}
      <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
        <div className="me-5 d-none d-lg-block">
          <span>Get connected with us on social networks:</span>
        </div>
        <div>
          <a href="#" className="me-4 text-reset">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="#" className="me-4 text-reset">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="#" className="me-4 text-reset">
            <i className="fab fa-google"></i>
          </a>
          <a href="#" className="me-4 text-reset">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="#" className="me-4 text-reset">
            <i className="fab fa-linkedin"></i>
          </a>
          <a href="#" className="me-4 text-reset">
            <i className="fab fa-github"></i>
          </a>
        </div>
      </section>

      {/* Useful Links and Contact Information */}
      <section className="footer-content mt-5">
        <div className="container text-center text-md-start mt-5">
          <div className="row mt-3">
            {/* Logo and Description */}
            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">
                <img src={logo} alt="Logo" width="60" height="60" />
                <i className="fas fa-shield-alt me-2"></i>Fix My Score
              </h6>
              <p>
                Licensed and bonded in accordance with state law. FixMyScore.LLC
              </p>
            </div>

            {/* Useful Links */}
            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Useful links</h6>
              <p><Link to="/packages" className="text-reset">Pricing</Link></p>
              <p>
                <a href="#" className="text-reset" onClick={handlePrivacyPolicyClick}>
                  Privacy Policy
                </a>
              </p>
              <p>
                <a href="#" className="text-reset" onClick={handleTermsOfServiceClick}>
                  Terms of Service
                </a>
              </p>
              <p>
                <a href="#" className="text-reset" onClick={handleRefundPolicyClick}>
                  Refund Policy
                </a>
              </p>
            </div>

            {/* Contact Information */}
            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
            <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
            <div className="contact-info">
              <p><i className="fas fa-home me-3"></i>6844 Bradstown, Louisville, KY 40291</p>
              <p><i className="fas fa-envelope me-3"></i>support@fixmyscore.llc</p>
              <p><i className="fas fa-phone me-3"></i>+ (117) 3954 783</p>
            </div>
          </div>
          </div>
        </div>
      </section>

      {/* Footer Bottom */}
      <div className="footer-bottom">
        © 2024 Copyright:
        <a className="text-reset fw-bold" href="https://fixmyscore.llc">https://fixmyscore.llc</a>
      </div>

      {/* Display the Privacy Policy Popup */}
      {showPrivacyPopup && <PrivacyPolicyPopup onClose={handleClosePrivacyPopup} />}

      {/* Display the Terms of Service Popup */}
      {showTermsPopup && <TermsOfServicePopup onClose={handleCloseTermsPopup} />}

      {/* Display the Refund Policy Popup */}
      {showRefundPopup && <RefundPolicyPopup onClose={handleCloseRefundPopup} />}
    </footer>
  );
}
