import React from 'react';
import './Navbar.css';
import logo from './pictures/logo.png';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate for navigation

function Navbar() {
  const navigate = useNavigate(); // Hook to programmatically navigate

  const handleGetStarted = () => {
    navigate('/get-started'); // Navigate to the Get Started form page
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <Link className="navbar-brand" to="/">
        <img src={logo} alt="Logo" width="30" height="30" />
        Fix My Score
      </Link>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <Link className="nav-link" to="/packages">Packages</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/faq">FAQ</Link> {/* Added FAQ link */}
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/refer">Refer a Friend</Link> {/* Added Refer a Friend link */}
          </li>
        </ul>
      </div>
      <div>
        <button className="btn btn-primary ml-2" onClick={handleGetStarted}>Get Started</button>
      </div>
    </nav>
  );
}

export default Navbar;
