import React from 'react';
import './PackagesSection.css';
import PackageCard from './PackageCard';
import CustomPlanForm from './CustomPlanForm';

function PackagesSection({ onPackageSelect }) {
  if (!onPackageSelect) {
    console.error('onPackageSelect is not defined!'); // Log an error if it's not passed
  }

  return (
    <div className="packages-section">
      <h2 className="section-title">Packages for your needs</h2>
      <p className="section-subtitle">We offer the most comprehensive service available in the industry today.</p>

      <div className="package-cards">
        <PackageCard
          title="Credit Reset"
          initialFee="$84.99"
          monthlyFee="$69.99"
          features={[
            "Unlimited Disputes to the Three Major Credit Bureaus",
            "Credit Score Analysis",
            "Letters to Creditors for Disputes",
            "Credit Score Monitoring",
            "Targeted Inquiry Handling",
            "Personalized Credit Improvement Guide",
            "Access to Educational Materials",
            "Live Account Syncing",
            "Online Customer Chat (Available 9 am - 7 pm EST, Monday to Friday)",
            "Three-Bureau Credit Reports and Scores",
          ]}
          onClick={() => onPackageSelect("Credit Reset")}
        />
        <PackageCard
          title="Credit Enhancement"
          initialFee="$59.99"
          monthlyFee="$44.99"
          features={[
            "Disputes with the Three Major Credit Bureaus",
            "Credit Score Evaluation",
            "Letters to Creditors for Disputes",
            "Credit Score Monitoring",
            "Targeted Inquiry Handling",
            "Single-Bureau Reports and Scores",
            "Real-Time Account Synchronization",
            "Live Chat Support (Available 9 AM-7 PM EST, Monday-Friday)",
          ]}
          onClick={() => onPackageSelect("Credit Enhancement")} // Use the prop
        />
        <CustomPlanForm />
      </div>
    </div>
  );
}

export default PackagesSection;
