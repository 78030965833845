import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Add useNavigate for redirection
import './Packages.css'; 
import PackagesSection from './packagesmodules/PackagesSection';
import PrivacyPolicyPopup from './packagesmodules/PrivacyPolicyPopup'; 

function Packages() {
  const location = useLocation();
  const formData = location.state?.formData; // Check if form data was passed from previous page
  const navigate = useNavigate(); // Initialize navigate for redirection

  const [selectedPackage, setSelectedPackage] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  // Handle package selection and redirect if no form data
  const handlePackageSelect = (packageName) => {
    console.log(`Selected package: ${packageName}`);
    setSelectedPackage(packageName);

    // If formData is not available, redirect to Get Started page
    if (!formData) {
      navigate('/get-started', { state: { selectedPackage: packageName } });
    } else {
      // Show the popup if formData exists (user came from the GetStarted page)
      setShowPopup(true);
    }
  };

  // Close the popup
  const handleClosePopup = () => {
    setShowPopup(false);
  };

  // Log formData to ensure data is passed successfully
  useEffect(() => {
    if (formData) {
      console.log('Form Data received in Packages component:', formData);
    } else {
      console.log('No form data found. Waiting for package selection to redirect.');
    }
  }, [formData]);

  return (
    <div className="page-container">
      {formData && (
        <h2>Welcome {formData.firstname}, Please Select Your Package</h2>
      )}

      {/* Pass the handlePackageSelect function as a prop to PackagesSection */}
      <PackagesSection onPackageSelect={handlePackageSelect} />

      {/* Display selected package */}
      {selectedPackage && formData && (
        <div className="selected-package-info">
          <h3>You selected: {selectedPackage}</h3>
        </div>
      )}

      {/* Show Privacy Policy Popup only if formData exists */}
      {showPopup && formData && (
        <PrivacyPolicyPopup
          formData={formData}
          selectedPackage={selectedPackage}
          onClose={handleClosePopup}
        />
      )}
    </div>
  );
}

export default Packages;
