import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import './MainContent.css';
import identity from './pictures/identitytheft.png';
import home from './pictures/home.png';
import healthcare from './pictures/healthcare.png';
import debt from './pictures/debt.png';
import creditscore from './pictures/creditscore.png';
import confidence from './pictures/confidence.png';
import interest from './pictures/interest.png';
import oppur from './pictures/oppur.png';
import comit from './pictures/comit.png';
import PackagesSection from './packagesmodules/PackagesSection'; // Import Packages Section

function MainContent() {
  const navigate = useNavigate(); // Initialize the navigate function

  // Handle the package selection to route to GetStarted
  const handlePackageSelect = (packageName) => {
    console.log(`Selected package: ${packageName}`);
    // Redirect to the GetStarted page, passing the selected package in the state
    navigate('/get-started', { state: { selectedPackage: packageName } });
  };

  return (
    <div className="main-content">
      {/* Heading */}
      <h1 className="main-heading">
        Get Fast, dependable credit repair<br />
        and take control of your <br />
        Financial Future.
      </h1>

      {/* Get Started Button */}
      <button className="btn-orange" onClick={() => navigate('/get-started')}>Get Started</button>

      {/* Benefits Section */}
      <h3 className="benefits-heading">
        Experience the advantages of a healthier credit profile with these key benefits:
      </h3>
      <div className="benefits-grid">
        {/* Benefit Boxes */}
        {[
          { img: creditscore, title: 'Improved Credit Score', desc: 'Enhance your credit score, making it easier to secure loans, mortgages, and credit cards with better terms.' },
          { img: interest, title: 'Lower Interest Rates', desc: 'Access lower interest rates on loans and credit cards, saving you money over time.' },
          { img: oppur, title: 'Increased Financial Opportunities', desc: 'Unlock more financial opportunities, such as qualifying for better loan amounts and favorable terms.' },
          { img: debt, title: 'Reduced Debt Stress', desc: 'Alleviate the burden of overwhelming debt through effective credit repair strategies and debt management.' },
          { img: home, title: 'Better Housing Options', desc: 'Improve your chances of being approved for rentals or mortgages, giving you more housing choices.' },
          { img: confidence, title: 'Enhanced Financial Confidence', desc: 'Gain the confidence to make informed financial decisions with a healthier credit profile.' },
          { img: identity, title: 'Protection Against Identity Theft', desc: 'Safeguard your credit report from fraudulent activities with our identity theft recovery services.' },
          { img: healthcare, title: 'Long-Term Financial Health', desc: 'Build a strong foundation for long-term financial success through personalized credit improvement plans and education.' }
        ].map((benefit, index) => (
          <div key={index} className="benefit-box">
            <span className="icon-placeholder">
              <img src={benefit.img} alt="Icon" />
            </span>
            <strong>{benefit.title}</strong>
            <p>{benefit.desc}</p>
          </div>
        ))}
      </div>

      {/* Commitment Section */}
      <div className="commitment-section">
        <div className="commitment-content">
          <img src={comit} alt="Icon" className="commitment-icon" />
          <div className="commitment-text">
            <h2>Our Assurance to You</h2>
            <p>
              At Fix My Score, we take the initiative to correct any inaccurate or unfair negative entries on your credit report. Our dedicated team works on your behalf, engaging with creditors and all three credit bureaus to ensure your credit accurately reflects your financial integrity.
            </p>
            <ul>
              <li>Dispute Collections</li>
              <li>Remove Late Payments</li>
              <li>Address Foreclosures</li>
              <li>Erase Charge Offs</li>
              <li>Resolve Bankruptcies</li>
              <li>Challenge Judgments</li>
              <li>Reverse Repossessions</li>
              <li>Remove Liens</li>
            </ul>
          </div>
        </div>
        <button className="btn-large-orange">Book a Call</button>
      </div>

      {/* Packages Section */}
      <PackagesSection onPackageSelect={handlePackageSelect} />

    </div>
  );
}

export default MainContent;
