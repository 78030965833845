import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './CustomPlanForm.css';
import ServiceCheckbox from './ServiceCheckbox';

function CustomPlanForm() {
  const [showForm, setShowForm] = useState(false);
  const [selectedServices, setSelectedServices] = useState({
    service1: false,
    service2: false,
    service3: false,
    service4: false,
    service5: false,
    service6: false,
    service7: false,
    service8: false,
    service9: false,
    service10: false,
  });

  const navigate = useNavigate();

  const serviceLabels = {
    service1: 'Unlimited dispute',
    service2: 'Credit score analysis',
    service3: 'Credit score monitoring',
    service4: 'Personalized credit improvement',
    service5: 'Letters to creditors',
    service6: 'Targeted inquiry handling',
    service7: 'Access to educational materials',
    service8: 'Live account syncing',
    service9: 'Online customer chat',
    service10: 'Three bureau-credit reports and chats',
  };

  const handleButtonClick = () => {
    setShowForm(!showForm);
  };

  const handleServiceChange = (e) => {
    const { name, checked } = e.target;
    setSelectedServices((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const calculateTotal = () => {
    const keyServiceCost = 12.6;
    const otherServiceCost = 5.6;
    let total = 0;

    total += selectedServices.service1 ? keyServiceCost : 0;
    total += selectedServices.service2 ? keyServiceCost : 0;
    total += selectedServices.service3 ? keyServiceCost : 0;
    total += selectedServices.service4 ? keyServiceCost : 0;
    total += selectedServices.service5 ? otherServiceCost : 0;
    total += selectedServices.service6 ? otherServiceCost : 0;
    total += selectedServices.service7 ? otherServiceCost : 0;
    total += selectedServices.service8 ? otherServiceCost : 0;
    total += selectedServices.service9 ? otherServiceCost : 0;
    total += selectedServices.service10 ? otherServiceCost : 0;

    return total.toFixed(2);
  };

  const calculateInitialMonthFee = () => {
    const keyServiceInitialFee = 15.5;
    const otherServiceInitialFee = 7.5;
    let initialFee = 0;

    initialFee += selectedServices.service1 ? keyServiceInitialFee : 0;
    initialFee += selectedServices.service2 ? keyServiceInitialFee : 0;
    initialFee += selectedServices.service3 ? keyServiceInitialFee : 0;
    initialFee += selectedServices.service4 ? keyServiceInitialFee : 0;
    initialFee += selectedServices.service5 ? otherServiceInitialFee : 0;
    initialFee += selectedServices.service6 ? otherServiceInitialFee : 0;
    initialFee += selectedServices.service7 ? otherServiceInitialFee : 0;
    initialFee += selectedServices.service8 ? otherServiceInitialFee : 0;
    initialFee += selectedServices.service9 ? otherServiceInitialFee : 0;
    initialFee += selectedServices.service10 ? otherServiceInitialFee : 0;

    return initialFee.toFixed(2);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const selectedServiceLabels = Object.keys(selectedServices)
      .filter((key) => selectedServices[key])
      .map((key) => serviceLabels[key]);

    const serviceDetails = {
      services: selectedServiceLabels,
      total: calculateTotal(),
      initialFee: calculateInitialMonthFee(),
      selectedPackage: 'Custom Plan',
    };

    navigate('/get-started', { state: serviceDetails });
  };

  return (
    <div className="package-card">
      <div className="package-title">Custom Plan</div>
      <div className="package-description">
        Tailored specifically to your financial goals, our custom plan provides a comprehensive strategy to optimize your credit score and secure a stronger financial future.
      </div>
      <button className="btn-custom-plan" onClick={handleButtonClick}>
        Make Your Freedom
      </button>

      <div className={`custom-plan-form-container ${showForm ? 'open' : ''}`}>
        <form className="custom-plan-form" onSubmit={handleSubmit}>
          <h3>Select Services</h3>
          <ServiceCheckbox
            name="service1"
            label="Unlimited dispute"
            onChange={handleServiceChange}
          />
          <ServiceCheckbox
            name="service2"
            label="Credit score analysis"
            onChange={handleServiceChange}
          />
          <ServiceCheckbox
            name="service3"
            label="Credit score monitoring"
            onChange={handleServiceChange}
          />
          <ServiceCheckbox
            name="service4"
            label="Personalized credit improvement"
            onChange={handleServiceChange}
          />
          <ServiceCheckbox
            name="service5"
            label="Letters to creditors"
            onChange={handleServiceChange}
          />
          <ServiceCheckbox
            name="service6"
            label="Targeted inquiry handling"
            onChange={handleServiceChange}
          />
          <ServiceCheckbox
            name="service7"
            label="Access to educational materials"
            onChange={handleServiceChange}
          />
          <ServiceCheckbox
            name="service8"
            label="Live account syncing"
            onChange={handleServiceChange}
          />
          <ServiceCheckbox
            name="service9"
            label="Online customer chat"
            onChange={handleServiceChange}
          />
          <ServiceCheckbox
            name="service10"
            label="Three bureau-credit reports and chats"
            onChange={handleServiceChange}
          />
          <div className="initial-month-fee">
            <strong>Initial Month Fee: ${calculateInitialMonthFee()}</strong>
          </div>
          <div className="total-cost">
            <strong>Monthly Fee: ${calculateTotal()}</strong>
          </div>
          <button type="submit" className="btn-submit">Submit</button>
        </form>
      </div>
    </div>
  );
}

export default CustomPlanForm;
